function Footer() {
  return (
    <footer>
      <span>
        Created By <a href="mailto:eklavya092@gmail.com"> Eklavya Sharma</a>
        &nbsp;|&nbsp;
        <span className="far fa-copyright"></span> 2021 All rights reserved.
      </span>
    </footer>
  );
}

export default Footer;
